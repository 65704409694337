.Container {
  height: 100vh;
}

.Header {
  height: 10vh;
  font-size: 3vh;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ImageContainer {
  height: 45vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.AddPhotoAlternate {
  color: white;
  font-size: 10vh !important;
}

.ClickDropLabel {
  color: white;
  font-size: 3vh;
}

.Image {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.ResultListContainer {
  height: 50vh;
  overflow: auto;
  background-color: white;
}

input[type="file"] {
  display: none;
}