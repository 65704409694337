.Card {
  width: 100%;
  height: 100vh;
  border-radius: 0 !important;
}

.CardContent {
}

.CardMedia {
  height: 40vh;
}

.Title {
  font-size: 14px;
}

.CardActions {
  justify-content: center;
}