html,
body,
#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.Container {
  height: 100vh;
}

.Header {
  height: 10vh;
  font-size: 3vh;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ImageContainer {
  height: 45vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.AddPhotoAlternate {
  color: white;
  font-size: 10vh !important;
}

.ClickDropLabel {
  color: white;
  font-size: 3vh;
}

.Image {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.ResultListContainer {
  height: 50vh;
  overflow: auto;
  background-color: white;
}

input[type="file"] {
  display: none;
}
.CircularProgress {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}
.App {
  text-align: center;
  height: 100%;
  background-color: #E0E0E0;
}

.AppGrid {
  height: 100vh;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
}

.GridPage {
  min-height: 100vh;
  border-radius: 4px;
  box-shadow: 0 3px 0 0 white, 0 -1px 0 0 white, 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.GridPage {
  background-color: white;
}
.SearchBar {
  position: -webkit-sticky;
  position: sticky;
  margin-left: 0;
  width: 100%;
  background-color: gainsboro;
  padding: 5px;
  display: flex;
  align-items: center;
}

.SearchIcon {
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.InputBaseRoot {
  width: 100%;
  background-color: gainsboro;
  padding: 5px;
  flex-grow: 1;
}

.InputBaseInput {
  width: 100%;
  background-color: red;
}

.ClearIcon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModelList {
  padding-bottom: 35px !important;
}

.BottomAppBar {
  top: auto !important;
  bottom: 0;
  background-color: lightgray !important;
}

.BottomAppBarToolBar {
  min-height: 35px !important;
  height: 35px;
  justify-content: center;
}
.Card {
  width: 100%;
  height: 100vh;
  border-radius: 0 !important;
}

.CardContent {
}

.CardMedia {
  height: 40vh;
}

.Title {
  font-size: 14px;
}

.CardActions {
  justify-content: center;
}
