.SearchBar {
  position: sticky;
  margin-left: 0;
  width: 100%;
  background-color: gainsboro;
  padding: 5px;
  display: flex;
  align-items: center;
}

.SearchIcon {
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.InputBaseRoot {
  width: 100%;
  background-color: gainsboro;
  padding: 5px;
  flex-grow: 1;
}

.InputBaseInput {
  width: 100%;
  background-color: red;
}

.ClearIcon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModelList {
  padding-bottom: 35px !important;
}

.BottomAppBar {
  top: auto !important;
  bottom: 0;
  background-color: lightgray !important;
}

.BottomAppBarToolBar {
  min-height: 35px !important;
  height: 35px;
  justify-content: center;
}